import { HeaderComponent } from './components/header-component';
import { RowComponent } from './components/row-component';
import { ComponentProps } from './layout-interfaces';
import { ColComponent } from './components/col-component';
import { FieldComponent } from './components/field-component';
import { FormComponent } from './components/form-component';
import { LayoutComponent } from './components/layout-component';
import { DataGridComponent } from '../dataGrid/dataGrid.component';
import { ButtonComponent } from './components/button-component';
import { TabsComponent } from './components/tabs-component';
import { ToolbarComponent } from './components/toolbar-component';
import { DropdownComponent } from './components/dropdown-component';
import { hasPermission, parseTemplate } from './component-hooks';
import { cloneElement, useContext, useMemo } from 'react';
import UiContext from '../../context/uiContext';
import { TextComponent } from './components/text-component';
import {
  NavDropdownComponent,
  NavbarComponent,
  NavbarItemComponent,
  NavbarLinkComponent,
} from './components/navbar-component';
import { LinkComponent } from './components/link-component';
import { Auth2Component } from '../oauth2/oauth2-component';
import { CollectionComponent } from './components/collection-component';
import { DataSourceComponent } from './components/datasource-component';
import { ImageComponent } from './components/image-component';
import { LineComponent } from './components/line-component';
import { CardComponent } from './components/card-component';
import { EmbedComponent } from './components/embed-component';
import logger from '../../logger';
import { SummaryComponent } from './components/summary-component';
import { BarCodeScannerComponent } from './components/barcodeScanner-component';
import { ScriptComponent } from './components/script.component';
import { BadgeComponent } from './components/badge-component';
import { Icon } from './components/icon-component';
import { PhotoComponent } from './components/photo-component';

const HiddenWrapper = ({ isHidden, children }) => {
  if (isHidden) {
    return <></>;
  }
  return <>{children}</>;
};

export const ComponentRender = (props: ComponentProps) => {
  const uiContext = useContext(UiContext);
  const isHidden = useMemo(() => {
    // check if permission is set
    if (props.props?.permission) {
      if (!hasPermission(props.props?.permission, props.variables)) {
        // do show the component if it is in development mode
        if (props.variables?.isDevelopment === true) return false;

        // log the component is hidden and the reason
        logger.log(
          `Component ${props.name} is hidden because of the permission: ${props.props.permission}`,
        );
        return true;
      }
    }
    // check if isVisible is set
    if (props.props?.isVisible) {
      const isVisibleResult = parseTemplate(props.props.isVisible, {
        ...props.variables,
        ...uiContext.storeValues,
      });
      if (isVisibleResult === true) {
        // log the component is hidden and the reason
        logger.debug(
          `Component ${props.name} is hidden because of the expression: ${props.props.isVisible}`,
        );
      }
      return !isVisibleResult;
    }

    // check if isHidden is set
    if (props.props?.isHidden) {
      const isHiddenResult = parseTemplate(props.props.isHidden, {
        ...props.variables,
        ...uiContext.storeValues,
      });
      if (isHiddenResult === true) {
        // log the component is hidden and the reason
        logger.debug(
          `Component ${props.name} is hidden because of the expression: ${props.props.isHidden}`,
        );
      }
      return isHiddenResult;
    }

    // show component by default
    return false;
  }, [
    props.props?.isHidden,
    props.props?.permission,
    props.variables,
    uiContext.storeValues,
  ]);

  const renderComponent = () => {
    switch (props.component) {
      case 'row':
        return <RowComponent {...props} />;
      case 'col':
        return <ColComponent {...props} />;
      case 'header':
        return <HeaderComponent {...props} />;
      case 'field':
        return <FieldComponent {...props} />;
      case 'form':
        return <FormComponent {...props} />;
      case 'layout':
        return <LayoutComponent {...props} />;
      case 'dataGrid':
        return <DataGridComponent {...props} />;
      case 'button':
        return <ButtonComponent {...props} />;
      case 'tabs':
        return <TabsComponent {...props} />;
      case 'toolbar':
        return <ToolbarComponent {...props} />;
      case 'dropdown':
        return <DropdownComponent {...props} />;
      case 'text':
        return <TextComponent {...props} />;
      case 'link':
        return <LinkComponent {...props} />;
      case 'navbar':
        return <NavbarComponent {...props} />;
      case 'navbarItem':
        return <NavbarItemComponent {...props} />;
      case 'navbarLink':
        return <NavbarLinkComponent {...props} />;
      case 'navDropdown':
        return <NavDropdownComponent {...props} />;
      case 'oauth2':
        return <Auth2Component {...props} />;
      case 'collection':
        return <CollectionComponent {...props} />;
      case 'datasource':
        return <DataSourceComponent {...props} />;
      case 'image':
        return <ImageComponent {...props} />;
      case 'embed':
        return <EmbedComponent {...props} />;
      case 'line':
        return <LineComponent {...props} />;
      case 'card':
        return <CardComponent {...props} />;
      case 'summary':
        return <SummaryComponent {...props} />;
      case 'barcodeScanner':
        return <BarCodeScannerComponent {...props} />;
      case 'script':
        return <ScriptComponent {...props} />;
      case 'badge':
        return <BadgeComponent {...props} />;
      case 'icon':
        return <Icon icon={props?.props?.icon} {...props} />;
      case 'photo':
        return <PhotoComponent {...props} />;
      default:
        if (props.context?.resolver) {
          // check if resolver is a function
          if (typeof props.context?.resolver === 'function') {
            return props.context?.resolver({
              component: props.component,
              props: props.props,
              variables: props.variables,
              name: props.name,
              context: props.context,
            });
          }
        }
        return (
          <div className="alert alert-danger" role="alert">
            Default Component <b>"{props.component}"</b> not found
          </div>
        );
    }
  };
  // render

  const renderWrappedComponent = () => {
    return cloneElement(props.wrapper, {}, renderComponent());
  };

  return (
    <HiddenWrapper isHidden={isHidden}>
      {props.wrapper ? renderWrappedComponent() : renderComponent()}
    </HiddenWrapper>
  );
};
