import { useCallback, useEffect, useMemo, useState } from 'react';
import UiContext from './uiContext';
import { AppComponent } from '../screens/appComponent.component';
import { useNavigate } from 'react-router-dom';
import {
  nullsToEmpty,
  localized,
  parseTemplate,
} from '../components/layout/component-hooks';
import { INTERNAL_PATH_TEMPLATE } from '../../../app.router';
import { addMessage } from '../messages.store';
import { v4 } from 'uuid';
import { showDialog } from '../dialog.store';
import { set, get, isArray } from 'lodash';
import printJS from 'print-js';
import { validationSchemaToYup } from '../components/layout/actions/validate';
import { ValidationError } from 'yup';
import { Confirm } from '../components/confirm/confirm.component';
import { dialogAction } from './actions/dialogAction';
import { fileUploadAction } from './actions/fileUploadAction';
import { queryGraphQlAction } from './actions/queryGraphQlAction';
import { mutationAction } from './actions/mutationAction';
import { workflowAction } from './actions/workflowAction';
import { fileDownloadAction } from './actions/fileDownloadAction';
import { setLocalStorageAction } from './actions/setLocalStorage';
import logger from '../logger';
import { forEachAction } from './actions/forEachAction';

export const confirmAction = async (actionProps) => {
  return showDialog({
    dialog: Confirm,
    props: {
      title: localized(actionProps.confirm?.title),
      message: localized(actionProps.confirm?.message),
      className: 'delete-modal',
    },
  });
};

export const UiContextProvider = ({
  children,
  setParams,
  additionalActions,
}) => {
  const [storeValues, setStoreValues] = useState({} as any);

  const resolver = useCallback(
    ({
      component,
      props,
      variables,
      name,
    }: {
      component: string;
      props?: any;
      variables: any;
      name?: string;
    }) => {
      return AppComponent({ component, variables, props, name });
    },
    [],
  );

  const setStore = (name: string, value: any) => {
    setStoreValues((prevState) => {
      // Split the name by dot to handle nested properties
      const nameParts = name.split('.');

      // Function to recursively update the state
      const assignValue = (obj: any, parts: string[], val: any): any => {
        if (parts.length === 1) {
          return { ...obj, [parts[0]]: val };
        } else {
          const [firstPart, ...restParts] = parts;
          // If the first part doesn't exist in the state, initialize it as an empty object
          const nestedObj = obj[firstPart] || {};
          return {
            ...obj,
            [firstPart]: assignValue(nestedObj, restParts, val),
          };
        }
      };

      // Use the recursive function to get the updated state
      return assignValue(prevState, nameParts, value);
    });
  };

  let navigate;

  try {
    // Try to get navigate function, will throw if not within Router context
    navigate = useNavigate();
  } catch (e) {
    // If we're not in a Router context, navigate will be undefined
    navigate = undefined;
  }

  const notificationAction = useCallback(
    (actionProps, data, latestStoreValues) => {
      const notification = parseTemplate(actionProps.notification, {
        ...latestStoreValues,
        ...data,
      });

      addMessage(notification);
    },
    [],
  );

  const navigateBackAction = useCallback(
    async (actionProps, data, latestStoreValues, source) => {
      // check if in dialog mode
      if (actions.close) {
        const closeAction = {
          close: actionProps.navigateBack || actionProps.navigateBackOrClose,
        };
        actions.close(closeAction, data, latestStoreValues);
        return;
      }
      // check if can go back
      if (window.history.length > 1) {
        window.history.back();
      } else if (actionProps.fallback) {
        await onAction(
          {
            navigate: actionProps.fallback,
          },
          data,
          latestStoreValues,
          source,
        );
      } else {
        // display error message
        addMessage({
          message: 'Cannot navigate back. Fallback action is missing.',
          type: 'danger',
          autoHide: true,
          id: v4(),
        });
      }
    },
    [],
  );

  const navigateAction = useCallback((actionProps, data, latestStoreValues) => {
    if (!navigate) {
      logger.error('Cannot use navigateAction outside of Router context');
      return;
    }

    const path =
      actionProps.navigate.externalLink ??
      INTERNAL_PATH_TEMPLATE + actionProps.navigate;

    const url = parseTemplate(path, {
      ...data,
      ...latestStoreValues,
    });

    if (actionProps.navigate.externalLink) {
      window.open(url, actionProps.navigate.target ?? '_self');
    } else {
      navigate(url, { unstable_flushSync: true });
    }
  }, []);

  const setStoreAction = useCallback(
    (actionProps, data, latestStoreValues) => {
      const parsedValues = parseTemplate(actionProps.setStore, {
        ...latestStoreValues,
        ...data,
      });

      setStoreValues((prevState) => {
        const newData = { ...prevState };
        Object.entries(parsedValues).forEach((entry) => {
          let key = entry[0];
          if (key.includes('{{') && key.includes('}}')) {
            key = parseTemplate(key, {
              ...latestStoreValues,
              ...data,
            });
          }
          if (typeof entry[1] === 'object' && !isArray(entry[1])) {
            set(newData, key, {
              ...get(newData, key),
              ...nullsToEmpty(entry[1]),
            });
          } else {
            set(newData, key, nullsToEmpty(entry[1]));
          }
        });

        return {
          ...newData,
        };
      });
    },
    [setStoreValues],
  );

  const setFieldsAction = useCallback(
    (actionProps, data, latestStoreValues, source) => {
      if (!source?.formikContext) {
        logger.error(
          'Component should take place in formikContext and provide it.',
        );
        return;
      }

      const parsedValues = parseTemplate(actionProps.setFields, {
        ...latestStoreValues,
        ...source.formikContext.values,
        ...data,
      });

      const newData = {
        ...source.formikContext.values,
        ...latestStoreValues?.[data?.formName],
      };

      Object.entries(parsedValues).forEach((entry) => {
        let key = entry[0];
        if (key.includes('{{') && key.includes('}}')) {
          key = parseTemplate(key, {
            ...latestStoreValues,
            ...data,
          });
        }
        if (entry[1] === null) {
          set(newData, key, entry[1]);
        } else if (typeof entry[1] === 'object' && !isArray(entry[1])) {
          set(newData, key, {
            ...get(newData, key),
            ...nullsToEmpty(entry[1]),
          });
        } else {
          set(newData, key, nullsToEmpty(entry[1]));
        }
      });

      Object.keys(actionProps.setFields).forEach((key) => {
        source.formikContext.setFieldValue(key, get(newData, key));
      });

      return newData;
    },
    [],
  );

  const ifAction = useCallback(
    async (actionProps, data, latestStoreValues, source) => {
      const ifCondition = parseTemplate(actionProps.if, {
        ...source?.formikContext?.values,
        ...latestStoreValues,
        ...data,
      });

      if (ifCondition) {
        return onAction(actionProps.then, data, latestStoreValues, source);
      } else if (actionProps.else) {
        return onAction(actionProps.else, data, latestStoreValues, source);
      }
      return true;
    },
    [],
  );

  const refreshAction = useCallback(
    async (actionProps, data, latestStoreValues, source) => {
      const handlerName = actionProps.refresh;
      setContext((prevState) => {
        return {
          ...prevState,
          refreshHandlers: {
            ...prevState.refreshHandlers,
            [handlerName]: prevState.refreshHandlers[handlerName]
              ? prevState.refreshHandlers[handlerName] + 1
              : 1,
          },
        };
      });
      return true;
    },
    [],
  );

  const printLabelAction = useCallback(
    (actionProps, data, latestStoreValues, source) => {
      const link = parseTemplate(actionProps.printLabel.link, {
        ...latestStoreValues,
        ...data,
      });
      const type = parseTemplate(actionProps.printLabel.type, {
        ...latestStoreValues,
        ...data,
      });
      printJS({
        printable: link,
        type,
      });
    },
    [],
  );

  const redirectAction = useCallback(
    (actionProps, data, latestStoreValues, source) => {
      const link = parseTemplate(actionProps.redirect.link, {
        ...latestStoreValues,
        ...data,
      });
      const blank = parseTemplate(actionProps.redirect.blank, {
        ...latestStoreValues,
        ...data,
      });
      if (blank) {
        window.open(link, '_blank');
      } else {
        window.open(link);
      }
    },
    [],
  );

  const setParamsAction = useCallback(
    (actionProps, data, latestStoreValues) => {
      const newParams = parseTemplate(actionProps.setParams, {
        ...latestStoreValues,
        ...data,
      });
      setParams(newParams);
    },
    [setParams],
  );

  const validateFormAction = useCallback(
    async (formikContext, validationSchema) => {
      if (validationSchema) {
        try {
          const schema = validationSchemaToYup(validationSchema);
          // Validate form values using the Yup schema
          await schema.validate(formikContext.values, {
            abortEarly: false,
          });
          // If no exception was thrown, it means validation passed
          return true;
        } catch (error) {
          if (error instanceof ValidationError) {
            // Set formik errors from the Yup ValidationError
            formikContext.setErrors(
              error.inner.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.path]: curr.message,
                }),
                {},
              ),
            );
          } else {
            // Re-throw the error if it's not a Yup ValidationError
            throw error;
          }
          return false;
        }
      } else {
        const errors = await formikContext.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }
      }
      return true;
    },
    [],
  );

  const validateStoreAction = useCallback(
    async (latestStoreValues, validationSchema) => {
      if (validationSchema) {
        try {
          const schema = validationSchemaToYup(validationSchema);
          // Validate form values using the Yup schema
          await schema.validate(latestStoreValues, {
            abortEarly: false,
          });
          // If no exception was thrown, it means validation passed
          return true;
        } catch (error) {
          if (error instanceof ValidationError) {
            // Show notifications
            error.errors.forEach((err) => {
              addMessage({
                message: err,
                type: 'danger',
                autoHide: true,
                id: v4(),
              });
            });
          } else {
            // Re-throw the error if it's not a Yup ValidationError
            throw error;
          }
          return false;
        }
      }
      return true;
    },
    [],
  );

  const actions = useMemo(() => {
    return {
      dialog: dialogAction,
      confirm: confirmAction,
      query: queryGraphQlAction,
      mutation: mutationAction,
      notification: notificationAction,
      navigateBack: navigateBackAction,
      navigateBackOrClose: navigateBackAction,
      navigate: navigateAction,
      workflow: workflowAction,
      setParams: setParamsAction,
      if: ifAction,
      validateForm: validateFormAction,
      fileUpload: fileUploadAction,
      setLocalStorage: setLocalStorageAction,
      refresh: refreshAction,
      forEach: forEachAction,
      ...additionalActions,
    };
  }, [
    notificationAction,
    navigateBackAction,
    navigateAction,
    additionalActions,
  ]);

  const onAction = useCallback(
    async (
      action: any,
      data: any,
      latestStoreValues: any,
      source?: any,
      prefix?: string,
    ) => {
      // if actionProps is array, then execute all actions
      if (Array.isArray(action)) {
        for (const actionItem of action) {
          const result = await onAction(
            actionItem,
            data,
            latestStoreValues,
            source,
            prefix,
          );
          if (result === false) {
            return false;
          }
        }
        return;
      }

      const actionProps = { ...action };

      // convert v1 action to v2 action
      if (actionProps.action) {
        actionProps[actionProps.action] = actionProps.props;
      }

      // set actions variables
      if (actionProps.setActionsVariables) {
        const actionsVariables = actionProps.setActionsVariables;
        if (actionsVariables) {
          const resultVars = Object.entries(actionsVariables).reduce(
            (acc, [key, value]) => {
              acc[key] = parseTemplate(value, {
                ...latestStoreValues,
                ...data,
              });
              return acc;
            },
            data,
          );
        }
      }

      if (actionProps.validateForm !== undefined) {
        const result = await validateFormAction(
          source?.formikContext,
          actionProps.validateForm.validationSchema,
        );
        if (result === false) {
          return false;
        }
      }

      if (actionProps.validateStore !== undefined) {
        const result = await validateStoreAction(
          latestStoreValues,
          actionProps.validateStore.validationSchema,
        );
        if (result === false) {
          return false;
        }
      }

      if (actionProps.setStore) {
        setStoreAction(actionProps, data, latestStoreValues);
      }

      if (actionProps.setFields) {
        if (source?.formikContext?.values) {
          const newValues = setFieldsAction(
            actionProps,
            data,
            latestStoreValues,
            source,
          );

          source.formikContext.values = { ...newValues };

          if (data?.formName && !newValues.hasOwnProperty('data')) {
            latestStoreValues[data.formName] = { ...newValues };
          }
        } else {
          logger.error(
            'Component should take place in formikContext and provide it.',
          );
        }
      }

      if (actionProps.if) {
        const result = await ifAction(
          actionProps,
          data,
          latestStoreValues,
          source,
        );
        if (result === false) {
          return false;
        }
      }

      if (actionProps.setLocalStorage) {
        setLocalStorageAction({
          actionProps,
          data,
          latestStoreValues,
          source,
          onAction,
        });
      }

      if (actionProps.fileUpload) {
        try {
          await fileUploadAction({
            actionProps,
            data,
            latestStoreValues,
            source,
            onAction,
          });
        } catch (e) {
          return false;
        }
      }

      if (actionProps.printLabel) {
        printLabelAction(actionProps, data, latestStoreValues, source);
      }

      if (actionProps.fileDownload) {
        const result = await fileDownloadAction({
          actionProps,
          data,
          latestStoreValues,
          source,
          onAction,
        });
        if (result === false) {
          return false;
        }
      }

      if (actionProps.redirect) {
        redirectAction(actionProps, data, latestStoreValues, source);
      }

      if (actionProps.dialog) {
        const dialogResult = await actions.dialog(
          actionProps,
          data,
          latestStoreValues,
          source,
        );
        if (dialogResult === false) {
          return;
        }
        if (actionProps.dialog.onClose) {
          await onAction(
            actionProps.dialog.onClose,
            {
              ...data,
              result: dialogResult,
            },
            latestStoreValues,
            source,
          );
        }
        if (dialogResult) return dialogResult;
      }

      if (actionProps.confirm) {
        const confirmResult = await actions.confirm(actionProps);
        return Boolean(confirmResult);
      }

      if (actionProps.query) {
        const result = await actions.query({
          actionProps,
          data,
          latestStoreValues,
          source,
          onAction,
        });
        if (result === false) {
          return false;
        }
      }

      if (actionProps.mutation) {
        const result = await actions.mutation({
          actionProps,
          data,
          latestStoreValues,
          source,
          onAction,
        });
        if (result === false) {
          return false;
        }
        for (const key in result) {
          if (result.hasOwnProperty(key)) {
            data[key] = result[key];
          }
        }
      }

      if (actionProps.workflow) {
        const result = await actions.workflow({
          actionProps,
          data,
          latestStoreValues,
          source,
          onAction,
        });
        if (result === false) {
          return false;
        }
        data.result = result;
      }

      if (actionProps.notification) {
        actions.notification(actionProps, data, latestStoreValues, source);
      }

      if (actionProps.navigateBack) {
        await actions.navigateBack(
          actionProps,
          data,
          latestStoreValues,
          source,
        );
        return false;
      }

      if (actionProps.setParams) {
        actions.setParams(actionProps, data, latestStoreValues, source);
      }

      if (actionProps.navigate) {
        actions.navigate(actionProps, data, latestStoreValues, source);
      }

      if (actionProps.refresh) {
        actions.refresh(actionProps, data, latestStoreValues, source);
      }

      if (actionProps.forEach) {
        const result = await actions.forEach({
          actionProps,
          data,
          latestStoreValues,
          source,
          onAction,
        });
        if (result === false) {
          return false;
        }
      }

      if (additionalActions) {
        for (const key in additionalActions) {
          if (actionProps[key] !== undefined) {
            additionalActions[key](
              actionProps,
              data,
              latestStoreValues,
              source,
            );
          }
        }
      }
      if (source?.actions) {
        for (const key in source.actions) {
          if (actionProps[key] !== undefined) {
            const result = source.actions[key](
              actionProps,
              data,
              latestStoreValues,
              source,
            );
            if (result === false) {
              return false;
            }
            break;
          }
        }
      }

      return true;
    },
    [actions],
  );

  useEffect(() => {
    const newContext = {
      ...context,
      action: async (actionProps, data, source, options) =>
        onAction(actionProps, data, storeValues, source, options),
      store: storeValues,
    };
    setContext(newContext);
  }, [storeValues, setParams, resolver]);

  const [context, setContext] = useState({
    setParams,
    setStore,
    resolver,
    action: onAction,
    store: storeValues,
    refreshHandlers: {},
  });

  const providerContext = useMemo(() => {
    return {
      context,
      storeValues,
    };
  }, [context, storeValues]);

  return (
    <UiContext.Provider value={providerContext} data-testid="context">
      {children(providerContext)}
    </UiContext.Provider>
  );
};
